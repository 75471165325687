import React from 'react';
import styled from 'styled-components';
import Layout from '@common/Layout';
import { Container, Section } from '@components/global';
import Navbar from '@common/Navbar';
import { Link } from 'gatsby';

const NotFoundPage = () => (
  <Layout>
    <Navbar />
    <Wrapper>
      <Container>
        <h1>
          Sidan kan inte hittas{' '}
          <span role="img" aria-label="sad emoji">
            😢
          </span>
        </h1>
        <H4Secondary>
          404, du har angett en sökväg som inte verkar existera.
        </H4Secondary>
        <h4>
          Tryck <Link to="/">här</Link> komma tillbaka till hemsidan
        </h4>
      </Container>
    </Wrapper>
  </Layout>
);

const Wrapper = styled(Section)`
  background: ${props => props.theme.color.background.primary};
  padding: 128px 0;
  margin-top: 160px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    padding-top: 0;
  }

  h1,
  h4 {
    text-align: center;
    a {
      color: ${props => props.theme.color.text.primary};
    }
  }
`;

const H4Secondary = styled.h4`
  opacity: 0.7;
`;

export default NotFoundPage;
